import React from 'react';
import "./../manage/assets/styles/404.scss";
import notFoundImg from "./../manage/assets/images/404.jpg";

function NotFound(props) {
    return (
        <div className="notFound">
            <div className="content">
                <div className="text">
                    <span className="code">404:</span> Страничка не найдена.
                    <img src={notFoundImg} />
                </div>
            </div>
        </div>
    );
}

export default NotFound;