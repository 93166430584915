import React, {useContext, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faTags, faCalendarDays, faRectangleList } from '@fortawesome/free-solid-svg-icons'
import MenuLink from "./ui/MenuLink";
import {MenuToggle} from "./../utils/MenuToggle";

function Menu(props) {
    const {menu, setMenu} = useContext(MenuToggle);
    const menuRef = useRef();

    const handleClick = e => {
        if (menuRef.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setMenu(false)
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <div className={"sideMenu" + (menu ? " open" : "")} ref={menuRef}>
            <MenuLink pathTo="/" icon={<FontAwesomeIcon icon={faHouse} />}>Мероприятия</MenuLink>
            <MenuLink pathTo="/eventTypes" icon={<FontAwesomeIcon icon={faTags} />}>Типы мероприятий</MenuLink>
            <MenuLink pathTo="/eventNew" icon={<FontAwesomeIcon icon={faCalendarDays} />}>Новое мероприятие</MenuLink>
            <MenuLink pathTo="/reports" icon={<FontAwesomeIcon icon={faRectangleList} />}>Отчеты</MenuLink>
        </div>
    );
}

export default Menu;