import React, {useState, useRef, useEffect, useContext} from 'react';
import PageTitle from "./../components/ui/PageTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faCircleMinus} from "@fortawesome/free-solid-svg-icons";
import TextInput from "./../components/ui/TextInput";
import InPageSectionTitle from "./../components/ui/InPageSectionTitle";
import Button from "./../components/ui/Button";
import SelectList from "./../components/ui/SelectList";
import FileUploader from "./../components/ui/FileUploader";
import {Editor} from "@tinymce/tinymce-react";
import {AppWideData} from "./../utils/AppWideData";
import {createEvent} from "manage/utils/Services/Events";
import Success from "manage/components/ui/Success";

function EventNew(props) {
    const editorRef = useRef(null);
    const {appWide, setAppWide} = useContext(AppWideData);
    const [okMsg, setOkMsg] = useState(false);

    const eventTypeOptions = [];
    appWide.events.types.map((item, index) => {
        eventTypeOptions.push({"optionValue": item.eventTypeID, "optionText": item.eventType});
    })

    const [ticketClasses, setTicketClasses] = useState([
        {"ticketClass": "", "ticketPrice": 0, "ticketCapacity": 0}
    ]);
    const handleTickets = (name, value, ind) => {
        let temp = [...ticketClasses];
        temp[ind][name] = value;
        setTicketClasses(temp);
    }
    const addTicketRow = () => {
        let temp = [...ticketClasses];
        temp.push({"ticketClass": "", "ticketPrice": 0, "ticketCapacity": 0});
        setTicketClasses(temp);
    }
    const deleteRow = (ind) => {
        let temp = [...ticketClasses];
        temp = [...temp.slice(0, ind), ...temp.slice(ind+1)];
        setTicketClasses(temp);
    }
    const [formData, setFormData] = useState({});

    const clearHTMLtags = (input) => {
        return input.replace(/<[^>]*>/g, '')
    }

    const changeInput = (fieldName, fieldValue) => {
        let temp = {...formData};
        if(fieldName === "eventMaxCap" && fieldValue < 0) {
            fieldValue = 0;
        }

        temp[fieldName] = fieldValue;
        setFormData(temp);
    }

    const selectHandle = (event) => {
        event.persist();

        let name = event.target.name;
        let val = clearHTMLtags(event.target.value);

        changeInput(name, val);
    }

    const submitForm = () => {
        createEvent(formData).then((item) => {
            if(item.status === 200) {
                setOkMsg(true);
                setTimeout(() => {
                    setOkMsg(false);
                }, 1500);
            }
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        changeInput("ticketClasses", ticketClasses);
    }, [ticketClasses])

    return okMsg ? <Success message="Мероприятие создано." /> : (
        <div className="page">
            <PageTitle icon={<FontAwesomeIcon icon={faCalendarDays} />}>Новое мероприятие</PageTitle>

            <InPageSectionTitle>Тип мероприятия</InPageSectionTitle>
            <SelectList options={eventTypeOptions}
                        inputName="eventTypeID" inputInitialValue="0"
                        changeFunction={selectHandle} />

            <InPageSectionTitle>Детали</InPageSectionTitle>
            <TextInput fieldType="text" fieldName="eventName" fieldLabel="Название" fieldOnChange={changeInput} />
            <TextInput fieldType="datetime-local" fieldName="eventDate" fieldLabel="Дата и время" fieldOnChange={changeInput} defaultValue={true} />
            <TextInput fieldType="number" fieldName="eventCapacity" fieldLabel="Максимальное кол-во гостей" fieldOnChange={changeInput} />

            <InPageSectionTitle>Изображение для афиши</InPageSectionTitle>
            <FileUploader />

            <InPageSectionTitle>Описание</InPageSectionTitle>
            <Editor
                textareaName="eventComments"
                tinymceScriptSrc="https://kislov.wine/tools/tinymce/tinymce.min.js"
                onInit={(evt, editor) => editorRef.current = editor}
                value={formData.eventDescription}
                onEditorChange={(newValue) => changeInput("eventComments", newValue)}
                init={{
                    menubar: true,
                    contextmenu: 'link image table',
                    toolbar: 'bold italic underline backcolor | alignleft aligncenter ' +
                        'alignright alignjustify',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />

            <InPageSectionTitle>Билеты</InPageSectionTitle>
            <table className="noHover">
                <tbody>
                {ticketClasses.map((item, index) => (
                        <tr key={"tickets_"+index}>
                            <td>
                                <TextInput fieldType="text" fieldName="ticketClass" fieldLabel="Класс" fieldOnChange={(name, value) => handleTickets(name, value, index)} />
                            </td>
                            <td>
                                <TextInput fieldType="number" fieldName="ticketPrice" fieldLabel="Цена" fieldOnChange={(name, value) => handleTickets(name, value, index)} defaultValue={true}>{item.ticketPrice}</TextInput>
                            </td>
                            <td>
                                <TextInput fieldType="number" fieldName="ticketCapacity" fieldLabel="Места" fieldOnChange={(name, value) => handleTickets(name, value, index)} defaultValue={true}>{item.ticketCapacity}</TextInput>
                            </td>
                            <td>
                                <span className="inPageLink icon" onClick={() => deleteRow(index)}><FontAwesomeIcon icon={faCircleMinus} /></span>
                            </td>
                        </tr>
                ))}

                    <tr>
                        <td colSpan="3">
                            <span className="inPageLink" onClick={() => addTicketRow()}>Добавить класс</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="rightAlign">
                <Button btnOnClick={() => submitForm()}>Сохранить</Button>
            </div>
        </div>
    );
}

export default EventNew;