import React, {useEffect, useState} from 'react';
import EventTitle from './../components/ui/EventTitle';
import CheckBox from "./../components/ui/CheckBox";
import "./../assets/styles/SingleEvent.scss";
import Button from "./../components/ui/Button";
import {Link, useNavigate, useParams} from "react-router-dom";
import EventDefaultImg from "./../assets/images/KislovEventDefault.png";
import {getDateMonthName} from "./../../utils/TranslateDate";
import Moment from "react-moment";
import parse from 'html-react-parser';
import {getEventByID, getSalesData} from "manage/utils/Services/Events";
import LoaderComponent from "manage/components/ui/Loader";
import ConfirmMsg from "manage/components/ui/ConfirmMsg";
import {cancelEvent} from "manage/utils/Services/Events";

function Event(props) {
    const [showDesc, setShowDesc] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);
    const [eventSales, setEventSales] = useState(null);
    const params = useParams();

    const [cancelConfirm, setCancelConfirm] = useState(false);

    const fillStates = () => {
        getEventByID(params.id).then(res => setEventDetails(res));
        getSalesData(params.id).then(res => setEventSales(res));
    }

    const goto = useNavigate();
    const gotoSale = (saleID) => {
        let path = `/event/${params.id}/${saleID}`;
        goto(path);
    }
    const statusTranslate = (status) => {
        let result = status;
        switch(status) {
            case "ok":
            case "paid":
                result = "ОК"
                break;
            case "new":
            case "open":
                result = "Новый";
                break;
            case "deposit":
                result = "Аванс";
                break;
            case "cancelled":
                result = "Отменен";
                break;
            default:
                result = "Неизвестно";
                break;
        }

        return result;
    }

    const confirmCancelEvent = () => {
        cancelEvent(params.id).then(item => {
            if(item.status === 200) {
                goto("/");
            }
        }).catch((err) => console.log(err));
    }

    useEffect(() => {
        fillStates();
    }, []);

    return eventDetails === null || eventSales === null ? <LoaderComponent /> : (
        <div className="page eventPage">
            {cancelConfirm ? <ConfirmMsg confirm={confirmCancelEvent} cancel={() => setCancelConfirm(false)} title={"Отменить мероприятие " + eventDetails.eventName}>При отмене мероприятия все заказы и билеты будут удалены из базы данных. Гости получат уведомление по e-mail об отмене.</ConfirmMsg> : ''}
            <EventTitle type={eventDetails.event_type.eventType}>{eventDetails.eventName}</EventTitle>
            <div className="eventImage" style={{backgroundImage: 'url('+EventDefaultImg+')'}}></div>

            <div className="eventDetails">
                <div className="detailsRow">
                    <div className="title">Дата и время:</div>
                    <div className="data">{getDateMonthName(eventDetails.eventDate)}, <Moment date={eventDetails.eventDate} format="HH:mm" /></div>
                </div>
                <div className="detailsRow">
                    <div className="title">Кол-во гостей:</div>
                    <div className="data">{eventDetails.eventSold}/{eventDetails.eventCapacity}</div>
                </div>
            </div>

            <div className="eventDescription">
                <div className="titleRow">
                    <div className="title">Описание:</div>
                    <div className="control">
                        <CheckBox label="Показать?" checkedBox={() => setShowDesc(!showDesc)} />
                    </div>
                </div>
                {showDesc ?
                    <div className="content">
                        {parse(eventDetails.eventComments)}
                    </div>
                : "" }
            </div>

            <div className="orders">
                <div className="title">Список гостей</div>
                {eventSales.length == 0 ?
                    <div className="general-alert">
                        На данное мероприятие записей нет.
                    </div>
                    :
                    <table>
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>Заказ на имя</th>
                            <th>Кол-во</th>
                            <th>Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                        {eventSales.map((item, index) => (
                            <tr key={"order_"+index} onClick={() => gotoSale(item.saleID)}>
                                <td>{index+1}</td>
                                <td>{item.customer.customerName}</td>
                                <td className="rightAlign">{item.tickets.length}</td>
                                <td className={"status " + item.saleStatus}>{statusTranslate(item.saleStatus)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
                <div className="addNew rightAlign">
                    <Link to={"/event/"+params.id+"/new"}><Button>Новая запись</Button></Link>
                </div>
                <div onClick={() => setCancelConfirm(true)}>
                    Отменить мероприятие
                </div>
            </div>
        </div>
    );
}

export default Event;