import useApi from "utils/api";

export async function getCustomerByPhone(phone) {

    try{
        const response = await useApi.get(`/customers/lookup/${phone}`);
        return response.data;
    }catch(error) {
        return [];
    }

}