import React, {useContext, useEffect, useState} from 'react';
import "./../assets/styles/manage.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import Moment from 'react-moment';
import EventDefaultImg from "./../assets/images/KislovEventDefault.png";
import {AppWideData} from "./../utils/AppWideData";
import {getSalesData} from "manage/utils/Services/Events";
import parse from 'html-react-parser';
import FlexRow from "manage/components/ui/FlexRow";

function SingleEventSummary(props) {
    const {appWide, setAppWide} = useContext(AppWideData);
    const eventData = props.eventData;
    const eventTypes = appWide.events.types;
    const currentEventType = eventTypes.find((item) => item.eventTypeID == eventData.eventTypeID);
    const eventImage = (eventData.eventImg !== null && eventData.eventImg !== undefined) ? "https://kislov.local"+eventData.eventImg : EventDefaultImg;
    const [awaitingSales, setAwaitingSales] = useState(0);
    const [summary, setSummary] = useState(appWide.events.data.find(item => item.eventID == eventData.eventID));
    const [textSummary, setTextSummary] = useState("");

    const printSalesSummary = (summaryData) => {
        let result = "";
        summaryData.forEach(item => {
            result += "<div>"+item.ticketClass + " " + item.sold + "</div>";
        });

        return result;
    }

    useEffect(() => {
        getSalesData(eventData.eventID).then(res => {
            const onlyNew = res.filter(item => item.saleStatus === 'open');
            setAwaitingSales(onlyNew.length)
        });
        const tmpSummary = appWide.events.data.find(item => item.eventID == eventData.eventID);
        setSummary(tmpSummary);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setTextSummary(printSalesSummary(summary.salesSummary));
        }, 500);
    }, [summary]);

    return (
        <div className="singleEventSummary">
            <div className="wrapEvent">
                <div className="eventImg" style={{backgroundImage: 'url('+eventImage+')'}}>
                    <div className="eventType">
                        {currentEventType?.eventType}
                    </div>
                </div>
                <div className="eventDetails">
                    <div className="eventTitle">
                        {eventData.eventName}
                    </div>
                    <div className="eventTime">
                        <Moment date={eventData.eventDate} format="DD/MM/YYYY" />, <Moment date={eventData.eventDate} format="HH:mm" />
                    </div>
                    {
                        (awaitingSales && awaitingSales !== 0) ?
                            <div className="awaitingApproval">
                                {awaitingSales} ожидают подтверждения
                            </div>
                            : ""
                    }
                    <div className="eventReport">
                        <b><u>Записано:</u></b>
                        <FlexRow>{parse(textSummary)}</FlexRow>
                        Свободных мест: {eventData.eventCapacity - eventData.eventSold}
                        <br />Выручка: {eventData.eventIncome} руб.
                    </div>
                    <div className="eventFooter">
                        <div className="footerBox">
                            <span className="copyLink"  onClick={() => navigator.clipboard.writeText(eventData.eventURL)}>Линк <FontAwesomeIcon icon={faClone} /></span>
                        </div>
                        <div className="footerBox rightAlign">
                            <Link to={"/event/"+eventData.eventID}>Управлять</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleEventSummary;