import useApi from "utils/api";

export async function getUpcomingEvents() {
    try{
        const response = await useApi.get('/events/upcoming');
        return response.data;
    }catch(error) {
        return error;
    }
}

export async function getAllEvents() {

    try{
        const response = await useApi.get('/events/allInd');
        return response.data;
    }catch(error) {
        return [];
    }

}

export async function createEvent(data) {
    const response = await useApi.post(`/events/add`, data);
    return response;
}

export async function uploadImage(img) {
    let data = new FormData();
    data.append("images", img);
    const response = await useApi.post(`/upload/up`, data);
    return response.data;
}

export async function getEventByID(eventID) {
    try{
        const response = await useApi.get(`/events/get/${eventID}`);
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function getEventTypeByID(eventTypeID) {
    try{
        const response = await useApi.get(`/events/types/find/${eventTypeID}`);
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function getSalesData(eventID) {
    try{
        const response = await useApi.get(`/sales/${eventID}`);
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function scanAndDecode(data) {
    const response = await useApi.post('/tickets/decode', data);
    return response.data;
}

export async function createEventType(data) {
    const response = await useApi.post(`/events/types/create`, data);
    return response;
}

export async function cancelEvent(eventID) {
    try {
        const response = await useApi.get(`/events/cancel/${eventID}`);
        return response;
    }catch(error) {
        return error;
    }
}
