import React from 'react';
import "./../assets/styles/manage.scss";

function Header(props) {
    return (
        <div className="header">
            <div className="kislov">КИСЛОВЪ</div>
        </div>
    );
}

export default Header;