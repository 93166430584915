import React, {useState} from 'react';

function CheckBox(props) {
    const [check, setCheck] = useState(false);
    const toggleCheckmark = () => {
        setCheck(!check);
        props.checkedBox();
    }
    return (
        <div className="checkBoxWrap" onClick={() => toggleCheckmark()}>
            {props?.label && <span className="label">{props.label}</span>}
            <div className="checkBox">
                <div className="checkmark">
                    {check ? <span>&#10003;</span> : ""}
                </div>
            </div>
        </div>
    );
}

export default CheckBox;