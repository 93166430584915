import useApi from "utils/api";

export async function getTicketClassData(ticketClassID) {
    try {
        const response = useApi.get(`/tickets/classes/data/${ticketClassID}`);
        return response;
    } catch (err) {
        return err;
    }
}

export async function removeTicket(saleID, ticketID) {
    try {
        const response = useApi.delete(`/sales/deleteTicket/${saleID}/${ticketID}`);
        return response;
    } catch(err) {
        return err;
    }
}