import React from 'react';
import "./../../assets/styles/Scanner.scss";

function SingleEvent(props) {
    const eventType = props.eventType?.slice(0, 4);
    return (
        <div className="scannerEventRow">
            <div className="eventDetails">
                <div className="eventTime">
                    {props.eventTime}
                    <div className="eventType">
                        {eventType}
                    </div>
                </div>
                <div className="eventName">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default SingleEvent;