import {
  BrowserRouter,
  Routes,
  Route, Navigate,
} from "react-router-dom";
import PublicApp from "./public/publicApp";
import ManageApp from "./manage/ManageApp";
import NotFound from "./public/NotFound";
import PrivateRoutes from "./utils/PrivateRoutes";
import Login from "./manage/pages/Login";
import "./manage/assets/styles/main.scss";
import {AuthProvider} from "utils/UserContext";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<PrivateRoutes />}>
              <Route path="*" element={<ManageApp />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/public" element={<PublicApp />} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
