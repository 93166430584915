import React, {useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import "./../../assets/styles/ui.scss";

function FileUploader(props) {
    const [uploadLabel, setUploadLabel] = useState("Выберите файл");
    const [imagePreview, setImagePreview] = useState(null);
    const [fileType, setFileType] = useState(null);

    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    const hiddenFileInput = useRef();
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    }
    const handleChange = (event) => {
        const uploadedFile = event.target.files[0];
        setUploadLabel(uploadedFile.name);
        if(uploadedFile.type.match(imageMimeType)) {
            setImagePreview(URL.createObjectURL(uploadedFile));
        }

        console.log(uploadedFile)
        props.handleFile(uploadedFile);
    }
    return (
        <div className="fileUploader" onClick={(e) => handleClick(e)}>
            <input type="file" ref={hiddenFileInput} style={{display: 'none'}} onChange={(e) => handleChange(e)} />
            <div className="selectFile">
                <span className="label">{uploadLabel}</span>
                <div className="uploadBtn">
                    <FontAwesomeIcon icon={faCloudArrowUp} />
                </div>
            </div>
            {imagePreview !== null ?
                <div className="previewImg">
                    <img src={imagePreview} alt="Preview"/>
                </div>
            : ''}
        </div>
    );
}

export default FileUploader;