import React, {createContext, useState, useEffect} from 'react'
import useApi from "utils/api";
import Loader from "manage/components/ui/Loader";

export const AppWideData = createContext({});

const AppWideDataContext = (props) => {
    const [appWide, setAppWide] = useState({"events": {"data": [], "types": []}});
    const [loading, setLoading] = useState(true);
    const [types, setTypes] = useState();
    const [events, setEvents] = useState();
    const [sales, setSales] = useState([]);

    const fetchTypes = async () => {
        await useApi.get("/events/types/all").then(item => setTypes(item.data));
    }

    const fetchEvents = async () => {
        await useApi.get("/events/allInd").then(item => setEvents(item.data));
    }

    const fetchSales = async () => {
        let eventsTmp = events;
        await eventsTmp.forEach((item, index) => {
            useApi.get(`/sales/${item.eventID}`).then(salesData => {
                eventsTmp[index].sales = salesData.data;
            });
            eventsTmp[index].salesSummary = [];
            item.ticket_classes.forEach((ticket, tktIndex) => {
                useApi.get(`/tickets/classes/data/${ticket.ticketClassID}`).then(ticketsData => {
                    let tmpData = ticketsData.data;
                    tmpData.ticketClass = item.ticket_classes[tktIndex].ticketClass;
                    eventsTmp[index].salesSummary.push(tmpData);
                })
            })
        })
        setEvents(eventsTmp);
    }

    const fetchAll = () => {
        fetchTypes();
        fetchEvents();
    }

    useEffect(() => {
        fetchAll();
    }, []);

    useEffect(() => {
        if(events) fetchSales();
    }, [events]);

    useEffect(() => {
        let tmpData = appWide;
        tmpData.events.types = types;
        tmpData.events.data = events;
        tmpData.events.sales = sales.length > 0 ? sales : [];
        setAppWide(tmpData);
        setLoading(false);
    }, [types, events, sales])

    return (
        <AppWideData.Provider value={{appWide, fetchAll}}>
            {loading ? <Loader /> : props.children}
        </AppWideData.Provider>
    )
}

export default AppWideDataContext;