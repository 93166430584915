import React from 'react';
import "./../../assets/styles/ui.scss"

function InPageSectionTitle(props) {
    return (
        <div className="inPageSectionTitle">
            {props.children}
        </div>
    );
}

export default InPageSectionTitle;