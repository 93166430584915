import React from 'react';
import "./../../assets/styles/ui.scss";

function SectionTitle(props) {
    return (
        <div className="sectionTitle">
            {props.children}
        </div>
    );
}

export default SectionTitle;