import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import {MenuToggle} from "./../../utils/MenuToggle";

function MenuLink(props) {
    const {menu, setMenu} = useContext(MenuToggle);
    return (
        <Link to={props.pathTo} onClick={() => setMenu(!menu)}>
            <div className="menuLink">
                <div className="icon">
                    {props.icon}
                </div>
                <div className="menuItem">
                    {props.children}
                </div>
            </div>
        </Link>
    );
}

export default MenuLink;