import React from 'react';

function ConfirmMsg(props) {
    return (
        <div className="popup-wrapper">
            <div className="popupBg" onClick={() => props.cancel()}></div>
            <div className="popup-wrap">
                <div className="popup-title">
                    {props.title}
                </div>
                <div className="popup-msg">
                    {props.children}
                </div>
                <div className="popup-confirm">
                    <span className="btn" onClick={() => props.confirm()}>ОК</span>
                    <span className="btn" onClick={() => props.cancel()}>Я еще подумаю...</span>
                </div>
            </div>
        </div>
    );
}

export default ConfirmMsg;