import React, {useEffect, useState} from 'react';
import EventTitle from './../components/ui/EventTitle';
import "./../assets/styles/SingleEvent.scss";
import Button from "./../components/ui/Button";
import TextInput from "./../components/ui/TextInput";
import InPageSectionTitle from "./../components/ui/InPageSectionTitle";
import {useNavigate, useParams} from "react-router-dom";
import CheckBox from "./../components/ui/CheckBox";
import {getEventByID, getSalesData} from "manage/utils/Services/Events";
import {getTicketClassesByEvent, createNewSale} from "manage/utils/Services/Sales";
import {getCustomerByPhone} from "manage/utils/Services/Customer";
import LoaderComponent from "manage/components/ui/Loader";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/ru.json';

function NewOrder(props) {
    const params = useParams();
    const goto = useNavigate();
    const [formData, setFormData] = useState(
        {
            "eventID": params.id,
            "saleDiscount": "0",
            "customerEmail": "",
            "customerInstagram": "",
            "customerPhone": ""
        }
    );
    const [eventData, setEventData] = useState(null);
    const [ticketClasses, setTicketClasses] = useState(null);
    const [discount, setDiscount] = useState(false);

    const [tickets, setTickets] = useState([]);
    const [total, setTotal] = useState(0);
    const [discountValue, setDiscountValue] = useState("0");
    const [anyDiscount, setAnyDiscount] = useState(false);

    const calculateTotal = (tickets) => {
        let totalAmount = 0;
        tickets.map(ticket => {
            let ticketPrice = ticketClasses.find(item => item.ticketClassID === ticket.ticketClassID).ticketPrice;
            totalAmount += ticketPrice * ticket.ticketsAmount;
        });
        totalAmount = handleDiscount(totalAmount);
        setTotal(totalAmount);
    }

    const handleTicketChange = (classID, amount) => {
        if(amount < 0) return;

        let temp = [...tickets];
        //check if there are any tickets of this type
        let currentTicket = temp.findIndex(item => item.ticketClassID === classID);
        if(currentTicket !== -1) {
            temp[currentTicket].ticketsAmount = amount;
        } else {
            temp.push({"ticketClassID": classID, "ticketsAmount": amount});
        }
        setTickets(temp);
        calculateTotal(temp);
    }

    const toggleDiscount = () => {
        if(discount) setDiscountValue("0");
        setDiscount(!discount);
        setAnyDiscount(false);
    }

    const handleDiscount = (total) => {
        switch (discountValue) {
            case "10%":
                total *= 0.9;
                break;
            case "15%":
                total *= 0.85;
                break;
            case "20%":
                total *= 0.8;
                break;
            default:
                total -= discountValue;
                break;
        }

        return Math.round(total / 5) * 5;
    }

    const clickDiscount = (amount) => {
        if(typeof amount === "string" && amount[amount.length-1] === "%") setAnyDiscount(false);
        setDiscountValue(amount);
    }

    const changeInput = (fieldName, fieldValue) => {
        let temp = {...formData};

        temp[fieldName] = fieldValue;
        setFormData(temp);
    }

    useEffect(() => {
        if(tickets.length > 0) calculateTotal(tickets);
        setFormData({...formData, "saleDiscount": discountValue});
    }, [discountValue])

    useEffect(() => {
        setFormData({...formData, "eventID": params.id});
        getEventByID(params.id).then(res => setEventData(res));
        getTicketClassesByEvent(params.id).then(res => setTicketClasses(res));
    }, []);

    useEffect(() => {
        setFormData({...formData, "saleTickets": tickets});
    }, [tickets]);

    const addNewOrder = () => {
        createNewSale(formData).then(res => {
            if(res.status === 200) goto(`/event/${params.id}`);
        }).catch(err => console.log("ERROR "+err.response.status, err.response.data));
    }

    //Customer autocomplete states
    const [phoneNum, setPhoneNum] = useState();
    const [customerFound, setCustomerFound] = useState([]);
    const [suggestedCustomers, setSuggestedCustomers] = useState([]);
    const chooseCustomer = (customer) => {
        setCustomerFound(customer);
    }
    useEffect(() => {
        if(phoneNum && phoneNum.toString().length > 7) {
            getCustomerByPhone(phoneNum).then(res => {
                setSuggestedCustomers(res);
                if(res.length === 0) {
                    setFormData({...formData, "customerPhone": phoneNum});
                }
            });
        }
    }, [phoneNum]);

    useEffect(() => {
        if(customerFound.customerID) {
            setSuggestedCustomers([]);
            let tmp = formData;
            delete tmp.customerEmail;
            delete tmp.customerInstagram;
            tmp.customer = customerFound;
            setFormData(tmp);
        }
    }, [customerFound]);

    return eventData == null ? <LoaderComponent /> : (
        <div className="page eventPage">
            <EventTitle type={eventData.event_type.eventType}>{eventData.eventName}</EventTitle>

            <div className="eventDetails">
                <div className="detailsRow">
                    <div className="title">Дата и время:</div>
                    <div className="data">12 июля 2022, 21:30</div>
                </div>
                <div className="detailsRow">
                    <div className="title">Свободных мест:</div>
                    <div className="data">{eventData.eventCapacity - eventData.eventSold}</div>
                </div>
            </div>

            <div className="orders">
                <InPageSectionTitle>Заказчик</InPageSectionTitle>
                <PhoneInput country={'md'} localization={ru} areaCodes={{md: ['777','778']}} masks={{md: '... ....... ..'}} preferredCountries={['md','ua']} value={phoneNum} onChange={(phone) => setPhoneNum(phone)} />
                {suggestedCustomers.length > 0 && (
                    <div className="suggestions-list">
                        {suggestedCustomers.map((item, index) => (
                            <div onClick={() => chooseCustomer(item)} key={index+item.customerID}>{item.customerName} - +{item.customerPhone}</div>
                        ))}
                    </div>
                )}
                {formData.hasOwnProperty("customer") ?
                    <>
                        <TextInput fieldType="text" fieldName="customerName" fieldLabel="Имя и фамилия" fieldOnChange={changeInput} defaultValue={true} fieldValue={customerFound.customerName} />
                        <TextInput fieldType="text" fieldName="customerEmail" fieldLabel="E-mail" fieldOnChange={changeInput} defaultValue={true} fieldValue={customerFound.customerEmail} />
                        <TextInput fieldType="text" fieldName="customerInstagram" fieldLabel="Instagram" fieldOnChange={changeInput} defaultValue={true} fieldValue={customerFound.customerInstagram} />
                    </>
                    :
                    <>
                        <TextInput fieldType="text" fieldName="customerName" fieldLabel="Имя и фамилия" fieldOnChange={changeInput} />
                        <TextInput fieldType="text" fieldName="customerEmail" fieldLabel="E-mail" fieldOnChange={changeInput} />
                        <TextInput fieldType="text" fieldName="customerInstagram" fieldLabel="Instagram" fieldOnChange={changeInput} />
                    </>
                }

                <InPageSectionTitle>Билеты</InPageSectionTitle>
                <table className="noHover">
                    <thead>
                    <tr>
                        <th style={{width: "40%"}}>Класс</th>
                        <th style={{width: "30%"}}>Цена</th>
                        <th style={{width: "30%"}}>Кол-во</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ticketClasses === null ? <tr><td colSpan="3"><LoaderComponent /></td></tr> : ticketClasses.map((item, index) => (
                        <tr key={"ticketClass_"+index}>
                            <td>{item.ticketClass}</td>
                            <td>{item.ticketPrice}</td>
                            <td><TextInput fieldOnChange={(name, val) => handleTicketChange(item.ticketClassID, val)} fieldType="number" fieldName="ticketsAmount" /></td>
                        </tr>
                    ))}
                    <tr className="summary">
                        <td>Итого</td>
                        <td colSpan="2" style={{textAlign: "right"}}>{total} руб.</td>
                    </tr>
                    {tickets.length > 0 &&
                        <tr>
                            <td><CheckBox label="Скидка" checkedBox={() => toggleDiscount()} /></td>
                            <td colSpan="2" style={{textAlign: "right"}}>
                                {discount &&
                                    <div className="discountOptions">
                                        <ul className="inlineLinks">
                                            <li onClick={() => clickDiscount("10%")}>10%</li>
                                            <li onClick={() => clickDiscount("15%")}>15%</li>
                                            <li onClick={() => clickDiscount("20%")}>20%</li>
                                            <li onClick={() => setAnyDiscount(!anyDiscount)}>Сумма</li>
                                        </ul>
                                        {anyDiscount &&
                                            <div>
                                                <input type="number"
                                                       onChange={(e) => clickDiscount(e.target.value)}/> руб.
                                            </div>
                                        }
                                    </div>
                                }
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>

                <InPageSectionTitle>Комментарии к заказу</InPageSectionTitle>
                <textarea name="saleComments" onChange={(e) => changeInput(e.target.name, e.target.value)}></textarea>

                <div className="addNew rightAlign">
                    <Button btnOnClick={addNewOrder}>Записать</Button>
                </div>
            </div>
        </div>
    );
}

export default NewOrder;