import useApi from "utils/api";

export async function getTransactionsBySale(saleID) {
    try{
        const response = await useApi.get(`/transactions/${saleID}`);
        return response.data;
    }catch(error) {
        return [error];
    }

}

export async function addTransaction(dataForm, typeIn) {
    let data = new FormData();
    for(let key in dataForm) {
        data.append(key, dataForm[key]);
    }
    if(typeIn === "bank") {
        data.append("images", dataForm.img);
    }
    return await useApi.post(`/transactions/add`, data);
}

export async function deleteTransaction(transactionID) {
    try {
        const response = await useApi.get(`/transactions/delete/${transactionID}`);
        return response.data;
    } catch(error) {
        return [error];
    }
}

export async function getRemaining(saleID) {
    try {
        const response = await useApi.get(`/transactions/remaining/${saleID}`);
        return response.data;
    } catch(error) {
        return [error];
    }
}
