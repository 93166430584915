import React from 'react';
import "./../assets/styles/manage.scss";
import EventsToday from "./../components/EventsToday";
import EventsFilter from "./../components/EventsFilter";

function AllEvents(props) {
    return (
        <div className="page">
            <EventsToday />
            <EventsFilter />
        </div>
    );
}

export default AllEvents;