import React, {useState, useEffect, useContext} from 'react';
import SingleEvent from "./../components/Scanner/SingleEvent";
import ScannerHeader from "./../components/Scanner/ScannerHeader";
import {Link} from "react-router-dom";
import {getTodayDate} from "./../../utils/TranslateDate";
import {getUpcomingEvents} from "manage/utils/Services/Events";
import {AppWideData} from "./../utils/AppWideData";

function Scanner(props) {
    const {appWide, setAppWide} = useContext(AppWideData);
    const eventTypes = appWide.events.types;
    const [allEvents, setAllEvents] = useState([]);

    const getEventsList = async () => {
        await getUpcomingEvents().then(response => {
            setAllEvents(response.filter((item) => item.eventDate.includes(getTodayDate())));
        });
    }

    const printTime = (dateStr) => {
        let date = new Date(dateStr);
        let hour = date.getHours();
        if(hour < 10) hour = "0" + hour;
        let minute = date.getMinutes();
        if(minute < 10) minute = "0" + minute;

        return hour + ":" + minute;
    }

    const printType = (typeID) => {
        let type;
        if(eventTypes) type = eventTypes.find((item) => item.eventTypeID == typeID);
        return type.eventType;
    }

    useEffect(() => {
        getEventsList();
    }, []);

    return (
        <div className="page">
            <ScannerHeader />
            Выберите мероприятие

            { allEvents.length === 0 || allEvents === undefined ?
                <div className="general-alert">Сегодня мероприятий нет.</div>
                :
                allEvents.map((item, index) => (
                    <Link key={"event_"+index} to={"/welcome/"+item.eventID}>
                        <SingleEvent key={index} eventTime={printTime(item.eventDate)} eventType={printType(item.eventTypeID)}>
                            {item.eventName}
                        </SingleEvent>
                    </Link>
                ))
            }
        </div>
    );
}

export default Scanner;