import React, {useState, useEffect} from 'react';
import Header from "./../components/Header";
import TextInput from "./../components/ui/TextInput";
import CheckBox from "./../components/ui/CheckBox";
import Button from "./../components/ui/Button";
import { useNavigate } from "react-router-dom";
import useApi from "./../../utils/api";
import { loginUser, useAuthState, useAuthDispatch } from "utils/UserContext";

function Login(props) {
    const [formData, setFormData] = useState({remember: false});
    //Check if form filled
    const [disableLogin, setDisableLogin] = useState(true);
    //Login errors
    const [loginErrors, setLoginErrors] = useState([]);
    const handleError = (msg) => {
        let tmp = [...loginErrors];
        tmp.push(msg);
        setLoginErrors(tmp);
    }

    let navigate = useNavigate();

    const { loading, errorMessage } = useAuthState();
    const dispatch = useAuthDispatch();

    const changeInput = (fieldName, fieldValue) => {
        let temp = {...formData};
        if(fieldName == "username") {
            temp.username = fieldValue;
        } else if(fieldName == "password") {
            temp.password = fieldValue;
        }

        setFormData(temp);
    }

    const checkRemember = () => {
        let temp = {...formData};
        temp.remember = !temp.remember;
        setFormData(temp);
    }

    const loginSubmit = async () => {
        const payload = {"email": formData.username, "password": formData.password};
        try {
            let response = await loginUser(dispatch, payload);
            if (!response.data.user) return;
            navigate('/');
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if(formData.username !== undefined && formData.password !== undefined && formData.username.length !== 0 && formData.password.length !== 0) {
            setDisableLogin(false);
        }
    }, [formData]);

    return (
        <div className="login">
            <Header />
            <div className="wrapper">
                <div className="loginContainer">
                    <div className="title">
                        Авторизация
                    </div>
                    {loginErrors.length !== 0 &&
                        <div className="errors">
                            {loginErrors.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))
                            }
                        </div>
                    }
                    <div className="formFields">
                        <TextInput fieldType="text" fieldName="username" fieldLabel="Логин" fieldOnChange={changeInput} />
                        <TextInput fieldType="password" fieldName="password" fieldLabel="Пароль" fieldOnChange={changeInput} />
                        <div className="rememberMe">
                            Запомнить меня? <CheckBox checkedBox={checkRemember} />
                        </div>
                        <div className="rightAlign">
                            <Button disabled={disableLogin} btnOnClick={loginSubmit}>Войти</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;