import React from 'react';
import "manage/assets/styles/ui.scss";

function GeneralMsg(props) {
    return (
        <div className="general-msg">
            {props.children}
        </div>
    );
}

export default GeneralMsg;