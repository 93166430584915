import axios from "axios";

let token = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")).token
    : "";

const useApi = axios.create({
    baseURL: 'https://api.kislov.wine',
    //baseURL: 'https://kislov.local',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
    },
    withCredentials: true
});

export default useApi;
