import { Outlet, Navigate, useLocation } from 'react-router-dom'
import {useLayoutEffect, useState, useEffect} from "react";
import {useAuthState} from "./UserContext";
import useApi from "./api";

const PrivateRoutes = () => {
    const isAuth = useAuthState();
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    const [checkValid, setCheckValid] = useState(false);

    useEffect(() => {
        useApi.get('/user/isTokenValid').then(res => {
            setCheckValid(true);
        }).catch(e => {
            setCheckValid(false);
        });
    }, []);

    return(
        (isAuth.token) ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes