const weekDays = ["понедельник", "вторник", "среда", "четверг", "пятница", "суббота", "воскресенье"];
const months = ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"];
const monthsPossesed = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

export const getMonthName = (month) => months[month];
export const getWeekDay = (date) => weekDays[date.getDay()];

export const getDateMonthName = (date) => {
    var t = date.split(/[- :]/);
    // Apply each element to the Date function
    var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
    date = new Date(d);
    let day = date.getDate();
    let month = monthsPossesed[date.getMonth()];
    let year = date.getFullYear();

    return day + " " + month + " " + year;
}

export const getTodayDate = () => {
    let date = new Date();
    let day = date.getDate();
    if(day < 10) day = "0" + day;
    let month = date.getMonth() + 1;
    if(month < 10) month = "0" + month;
    let year = date.getFullYear();

    return year+"-"+month+"-"+day;
}