import React, {useEffect, useState, useContext} from 'react';
import SingleEvent from "./../components/Scanner/SingleEvent";
import ScannerHeader from "./../components/Scanner/ScannerHeader";
import {QrReader} from "react-qr-reader";
import {scanAndDecode, getEventByID} from "./../utils/Services/Events";
import {useParams} from "react-router-dom";
import {AppWideData} from "./../utils/AppWideData";

function ScannerEvent(props) {
    const {appWide, setAppWide} = useContext(AppWideData);
    const eventTypes = appWide.events.types;
    const [processing, setProcessing] = useState(false);
    const [scanResult, setScanResult] = useState(null);
    const [currentEvent, setCurrentEvent] = useState();

    const params = useParams();

    const handleScan = (scanData) => {
        if(scanData?.text && scanData?.text.length > 20 && scanData?.text.length < 40) {
            scanAndDecode({"eventID": params.id, "qrCode": scanData.text}).then(item => {
                if(item === null || item === "") setScanResult("error")
                    else setScanResult(item);
            }).catch(err => console.log(err));
            setProcessing(true);
        }
    };
    const handleError = (err) => {
        console.error(err);
    };

    const okNext = () => {
        setProcessing(false);
        setScanResult(null);
    }

    const ticketStatusCheck = (status) => {
        let result = "OK";
        switch(status) {
            case "arrived":
                result = "Гость принят ранее!";
                break;
            case "generated":
                result = "Заказ не оплачен";
                break;
            default:
                result = "OK";
        }

        return result;
    }

    const printTime = (dateStr) => {
        let date = new Date(dateStr);
        let hour = date.getHours();
        if(hour < 10) hour = "0" + hour;
        let minute = date.getMinutes();
        if(minute < 10) minute = "0" + minute;

        return hour + ":" + minute;
    }

    const printType = (typeID) => {
        let type;
        if(eventTypes) type = eventTypes.find((item) => item.eventTypeID == typeID);
        return type.eventType;
    }

    useEffect(() => {
        getEventByID(params.id).then(item => {
            setCurrentEvent(item);
        })
    }, [])

    useEffect(() => {
        console.log(currentEvent)
    }, [currentEvent])

    return (
        <div className="page">
            <ScannerHeader />
            {currentEvent &&
                <SingleEvent eventTime={printTime(currentEvent.eventDate)} eventType={printType(currentEvent.eventTypeID)}>
                    {currentEvent.eventName}
                </SingleEvent>
            }
            {!processing &&
                <QrReader
                    onResult={handleScan}
                    constraints={{facingMode: 'environment'}}
                    delay={500}
                    onError={handleError}
                    onScan={handleScan}
                    // chooseDeviceId={()=>selected}
                />
            }

            {scanResult === "error" && processing &&
                <div>
                    Билет не обнаружен.
                    <button onClick={okNext}>OK, next</button>
                </div>
            }
            {scanResult !== "error" && processing &&
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Номер билета</td>
                                <td>{scanResult?.ticketNum || "Не найден"}</td>
                            </tr>
                            <tr>
                                <td>Статус билета</td>
                                <td>{scanResult?.ticketStatus ? ticketStatusCheck(scanResult.ticketStatus) : "Не найден" || "Не найден"}</td>
                            </tr>
                            <tr>
                                <td>Класс билета</td>
                                <td>{scanResult?.ticket_class?.ticketClass || "Не найден"}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><button onClick={okNext}>OK, next</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }

        </div>
    );
}

export default ScannerEvent;