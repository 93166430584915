import React from 'react';
import "./../../assets/styles/ui.scss";

function Button(props) {
    return (
        <div className="button">
            <button onClick={props.btnOnClick} disabled={props?.disabled}>
                {props.children}
            </button>
        </div>
    );
}

export default Button;