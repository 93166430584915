import React, {useEffect, useState} from 'react';
import FileUploader from "manage/components/ui/FileUploader";
import {useParams} from "react-router-dom";
import {getTransactionsBySale, addTransaction, deleteTransaction, getRemaining} from "manage/utils/Services/Transactions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBills, faCreditCard} from "@fortawesome/free-solid-svg-icons";
import ToggleButton2 from "manage/components/ui/ToggleButton2";

function PaymentConfirm(props) {
    const params = useParams();
    const saleID = params.saleID;

    const [updatedData, setUpdatedData] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [openBlock, setOpenBlock] = useState(false);
    const [remaining, setRemaining] = useState(0);
    const [transactionOptions, setTransactionOptions] = useState(
        {
            "saleID": saleID,
            "transactionIn": null,
            "transactionAmount": 0
        }
    );

    const [file, setFile] = useState(null);

    const uploadThisFile = (file) => {
        setFile(file);
    }

    const translateTransactionIn = (type) => {
        let result = "";
        switch (type) {
            case "cash":
                result = "Наличные";
                break;
            case "bank":
                result = "Банк";
                break;
            case "refund":
                result = "Возврат";
                break;
            default:
                result = "N/A";
        }

        return result;
    }

    const toggleTransactionIn = (type) => {
        if(type === "left")
            setTransactionOptions({...transactionOptions, transactionIn: "cash"});
        else if(type === "right")
            setTransactionOptions({...transactionOptions, transactionIn: "bank"});
        else setTransactionOptions({...transactionOptions, transactionIn: null});

    }

    const addTransactionToDB = () => {
        addTransaction(transactionOptions, transactionOptions.transactionIn).then(res => handleSubmittedTransaction(res));
    }

    const handleSubmittedTransaction = (res) => {
        if(res.status === 200) {
            setOpenBlock(false);
            setUpdatedData(true);
            setTimeout(() => {
                setUpdatedData(false);
            }, 500)
            props.done();
        }
    }

    const handleDeleteTransaction = (transactionID) => {
        deleteTransaction(transactionID).then(res => {
            if(res.status === 200) {
                setOpenBlock(false);
                setUpdatedData(true);
                setTimeout(() => {
                    setUpdatedData(false);
                }, 500);
                props.done();
            }
        });
    }

    useEffect(() => {
        if(file !== null) setTransactionOptions({...transactionOptions, "img": file});
    }, [file]);

    useEffect(() => {
        getTransactionsBySale(saleID).then(res => setTransactions(res));
        getRemaining(saleID).then(res => setRemaining(res));
    }, [])

    useEffect(() => {
        if(updatedData) {
            getTransactionsBySale(saleID).then(res => setTransactions(res));
            getRemaining(saleID).then(res => setRemaining(res));
        }
    }, [updatedData]);

    return (
        <div className="inpageBlock">
            <div className="title">
                Транзакции
            </div>
            {transactions.length > 0 ?
                <div className="transactionsTable">
                    <table>
                        <tbody>
                        {transactions.map((item, index) => (
                            <tr key={index}>
                                <td>{item.transactionAmount} руб.</td>
                                <td>{translateTransactionIn(item.transactionIn)}</td>
                                <td><span className="inPageLink" onClick={() => handleDeleteTransaction(item.transactionID)}>Удалить</span></td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                </div>
                : <div className="general-alert">Транзакций нет.<br/>К оплате {remaining} руб.</div>
            }

            <strong>Осталось к оплате:</strong> {remaining} руб.
            {remaining > 0 ?
                <div className="rightAlign">
                    <span className="inPageLink" onClick={() => setOpenBlock(!openBlock)}>Добавить транзакцию +</span>
                </div>
            : ''}

            {openBlock &&
                <div>
                    <ToggleButton2 option1="Наличные" option1icon={<FontAwesomeIcon icon={faMoneyBills} />} option2="Банк" option2icon={<FontAwesomeIcon icon={faCreditCard} />} onChosenOption={toggleTransactionIn} />
                    <div className="transactionBlock">
                        <div className="title">Укажите сумму</div>
                        <input type="number" value={transactionOptions.transactionAmount} onChange={(e) => setTransactionOptions({...transactionOptions, "transactionAmount": e.target.value})} />
                        {transactionOptions.transactionIn === "bank" ?
                            <div>
                                <div className="title">Загрузите подтверждение оплаты</div>
                                <FileUploader handleFile={uploadThisFile} />
                            </div>
                            : ""
                        }

                        <span className="inPageLink" onClick={() => addTransactionToDB()}>Добавить +</span>
                    </div>
                </div>
            }

        </div>
    );
}

export default PaymentConfirm;