import React, {useState, useEffect} from 'react';
import PageTitle from "./../components/ui/PageTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTags} from "@fortawesome/free-solid-svg-icons";
import TextInput from "./../components/ui/TextInput";
import InPageSectionTitle from "./../components/ui/InPageSectionTitle";
import Button from "./../components/ui/Button";
import {createEventType} from "manage/utils/Services/Events";
import Success from "manage/components/ui/Success";
import useApi from "utils/api";
import Loader from "manage/components/ui/Loader";

function EventTypes(props) {
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({});
    const [okMsg, setOkMsg] = useState(false);


    const changeInput = (fieldName, fieldValue) => {
        let temp = {...formData};
        temp[fieldName] = fieldValue;
        setFormData(temp);
    }

    const fetchTypes = async () => {
        await useApi.get("/events/types/all").then(item => {
            setTypes(item.data)
            setLoading(false);
        });
    }

    const addType = () => {
        createEventType(formData).then(item => {
            if(item.status === 201) {
                setOkMsg(true);
                setTimeout(() => {setOkMsg(false)}, 1500);
            }
        });
        setLoading(true);
        fetchTypes();
    }

    useEffect(() => {
        fetchTypes();
    }, []);

    return (
        <div className="page">
            <PageTitle icon={<FontAwesomeIcon icon={faTags} />}>Типы мероприятий</PageTitle>

            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Наименование</th>
                        <th>Локация</th>
                    </tr>
                </thead>
                <tbody>
                {loading ?
                    <tr>
                        <td colSpan="3"><Loader /></td>
                    </tr>
                    : types.map((item,index) => (
                    <tr key={"type_"+index}>
                        <td>{index+1}</td>
                        <td>{item.eventType}</td>
                        <td>{item.eventLocation}</td>
                    </tr>
                ))
                }
                </tbody>
            </table>

            <InPageSectionTitle>Добавить новый тип</InPageSectionTitle>
            {okMsg ? <Success message="Тип мероприятия добавлен." /> :
                <div>
                    <TextInput fieldType="text" fieldName="eventType" fieldLabel="Наименование" fieldOnChange={changeInput} />
                    <TextInput fieldType="text" fieldName="eventLocation" fieldLabel="Локация" fieldOnChange={changeInput} />
                    <div className="rightAlign">
                        <Button btnOnClick={addType}>Добавить</Button>
                    </div>
                </div>
            }
        </div>
    );
}

export default EventTypes;