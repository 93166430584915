import React from 'react';
import "./../assets/styles/manage.scss"

function Footer(props) {
    return (
        <div className="footer">
            Винодельческое хозяйство КИСЛОВЪ &copy; 2022
        </div>
    );
}

export default Footer;