import React, {useEffect, useState} from 'react';
import "manage/assets/styles/ui.scss";

function ToggleButton2(props) {
    const [toggle, setToggle] = useState(null);
    const toggleBtns = (btn) => {
        if(btn == "left") setToggle("left");
        else setToggle("right");
    }

    useEffect(() => {
        props.onChosenOption(toggle);
    }, [toggle]);

    return (
        <div className="toggleButton2">
            <div className={"option" + ((toggle == "left") ? " chosen" : "")} onClick={() => toggleBtns("left")}>
                <div className="icon">{props.option1icon}</div>
                {props.option1}
            </div>
            <div className={"option" + ((toggle == "right") ? " chosen" : "")} onClick={() => toggleBtns("right")}>
                <div className="icon">{props.option2icon}</div>
                {props.option2}
            </div>
        </div>
    );
}

export default ToggleButton2;