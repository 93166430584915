import React, {useState} from 'react';
import "./../../assets/styles/ui.scss"

function TextInput(props) {
    const valDefault = props.defaultValue ? true : false;
    const [inputContent, setInputContent] = useState(valDefault);
    const inputFocus = (value) => {
        if(value.target.value.length > 0) {
            setInputContent(true);
        }
        else {setInputContent(false);}
        props.fieldOnChange(value.target.name, value.target.value);
    }
    return (
        <div className="textInput">
            <span className={"fieldLabel" + ((inputContent || valDefault) ? " content" : "")}>{props.fieldLabel}</span>
            <input onChange={(e) => inputFocus(e)} type={props.fieldType} name={props.fieldName} autoComplete="off" value={props?.fieldValue ? props.fieldValue : props.children}/>
        </div>
    );
}

export default TextInput;