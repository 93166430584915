import useApi from "../api";

export async function loginUser(dispatch, loginPayload) {
    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        const result = await useApi.post('/user/login', loginPayload);
        console.log(result)
        if(result.data.user) {
            dispatch({type: 'LOGIN_SUCCESS', payload: result.data});
            localStorage.setItem("currentUser", JSON.stringify(result.data));
            return result;
        }

        dispatch({type: "LOGIN_ERROR", error: result.errors[0]});
        return;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}

export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
}
