import React from 'react';

function FlexRow(props) {
    return (
        <div className="flex-row">
            {props.children}
        </div>
    );
}

export default FlexRow;