import React from 'react';
import "./../../assets/styles/ui.scss";

function PageTitle(props) {
    return (
        <div className="pageTitle">
            {props.icon ? <div className="icon">{props.icon}</div> : ""}
            <div className="title">{props.children}</div>
        </div>
    );
}

export default PageTitle;