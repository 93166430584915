import React, {useEffect, useState} from 'react';
import EventTitle from './../components/ui/EventTitle';
import "./../assets/styles/SingleEvent.scss";
import Button from "./../components/ui/Button";
import TextInput from "./../components/ui/TextInput";
import InPageSectionTitle from "./../components/ui/InPageSectionTitle";
import {useNavigate, useParams} from "react-router-dom";
import {getDateMonthName} from "./../../utils/TranslateDate";
import Moment from "react-moment";
import {getEventByID, getSalesData} from "manage/utils/Services/Events";
import {getSaleInfo, getTicketClassesByEvent} from "manage/utils/Services/Sales";
import LoaderComponent from "manage/components/ui/Loader";
import PaymentConfirm from "manage/components/PaymentConfirm";
import GeneralMsg from "manage/components/ui/GeneralMsg";
import ConfirmMsg from "manage/components/ui/ConfirmMsg";
import {updateSale} from "manage/utils/Services/Sales";
import {removeTicket} from "manage/utils/Services/Tickets";

function EditOrder(props) {
    const goto = useNavigate();

    const [eventData, setEventData] = useState(null);
    const [saleData, setSaleData] = useState(null);
    const [tktClasses, setTktClasses] = useState(null);
    const [ableToSave, setAbleToSave] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [allLoaded, setAllLoaded] = useState(false);
    const [cancelOrder, setCancelOrder] = useState(false);
    const [cancelTicket, setCancelTicket] = useState(false);
    const [cancellationTkt, setCancellationTkt] = useState(null);

    const copyLink = (link) => {
        navigator.clipboard.writeText(link);
        setShowMsg(true);
        setTimeout(() => {
            setShowMsg(false);
        }, 2500);
    }

    const params = useParams();

    const changeComments = (val) => {
        let tmp = saleData;
        tmp.saleComments = val;
        setSaleData(tmp);
    }

    const handleCancelOrder = () => {
        setCancelOrder(true);
    }

    const handleCancelTicket = (ticketID) => {
        setCancellationTkt(ticketID);
        setCancelTicket(true);
    }

    const handleConfirmCancelOrder = () => {
        //close confirmation popup
        setCancelOrder(false);
        //api call to cancel
        const data = {
            "saleStatus": "cancelled",
            "saleComments": saleData.saleComments
        }
        updateSale(params.saleID, data).then(item => {
            if(item.result === "OK") {
                //navigate to prev page
                goto(`/event/${params.id}`)
            }
        }).catch((err) => console.log(err));
    }

    const handleConfirmCancelTicket = () => {
        //close confirmation popup
        setCancelTicket(false);
        //api call to cancel
        removeTicket(params.saleID, cancellationTkt).then(item => {
            if(item.status === 200) {
                //navigate to prev page
                getSaleInfo(params.saleID).then(res => setSaleData(res));
            }
        }).catch((err) => console.log(err));

        setCancellationTkt(null);
    }

    const transalteStatus = (status) => {
        let result = "";
        switch(status) {
            case "open":
                result = "Новый";
                break;
            case "deposit":
                result = "Аванс";
                break;
            case "paid":
                result = "Оплачен";
                break;
            case "cancelled":
                result = "Отменен";
                break;
            default:
                result = "N/A";
                break;
        }

        return result;
    }

    const saveComments = () => {
        console.log("called")
        const data = {
            "saleStatus": saleData.saleStatus,
            "saleComments": saleData.saleComments
        }

        console.log("data to update", data)
        updateSale(params.saleID, data).then(item => {
            console.log("updated", item);
            if(item.result === "OK") {
                //navigate to prev page
                goto(`/event/${params.id}`)
            }
        }).catch((err) => console.log(err));
    }

    useEffect(() => {
        getEventByID(params.id).then(res => setEventData(res));
        getTicketClassesByEvent(params.id).then(res => setTktClasses(res));
        getSaleInfo(params.saleID).then(res => setSaleData(res));
    }, []);

    useEffect(() => {
        if(eventData !== null && saleData !== null && tktClasses !== null) setAllLoaded(true);
    }, [saleData, tktClasses, eventData])

    const getTktClass = (tktClassID) => {
        if(tktClasses !== null) {
            let temp = tktClasses.find(item => item.ticketClassID == tktClassID);
            return temp.ticketClass;
        }
        return "N/A";
    }

    return !allLoaded ? <LoaderComponent /> : (
        <div className="page eventPage">
            {cancelOrder && <ConfirmMsg confirm={handleConfirmCancelOrder} cancel={() => setCancelOrder(false)} title={"Отменить заказ №" + params.saleID} />}
            {cancelTicket && <ConfirmMsg confirm={() => handleConfirmCancelTicket(cancellationTkt)} cancel={() => setCancelTicket(false)} title={"Отменить билет"} />}
            <EventTitle type={eventData.event_type.eventType}>{eventData.eventName}</EventTitle>

            <div className="eventDetails">
                <div className="detailsRow">
                    <div className="title">Заказ №:</div>
                    <div className="data">{params.saleID}</div>
                </div>
                <div className="detailsRow">
                    <div className="title">Дата и время:</div>
                    <div className="data">{getDateMonthName(eventData.eventDate)}, <Moment date={eventData.eventDate} format="HH:mm" /></div>
                </div>
                <div className="detailsRow">
                    <div className="title">Статус:</div>
                    <div className="data">
                        {transalteStatus(saleData.saleStatus)}
                    </div>
                </div>
                {(saleData.saleStatus !== 'cancelled') &&
                    <div className="detailsRow">
                        <div className="title"></div>
                        <div className="data">
                            <button className="cancel-btn" onClick={() => handleCancelOrder()}>Отменить</button>
                        </div>
                    </div>
                }
                <PaymentConfirm done={() => getSaleInfo(params.saleID).then(res => setSaleData(res))} />
            </div>

            <div className="orders">
                <InPageSectionTitle>Заказчик</InPageSectionTitle>
                <TextInput fieldType="text" fieldName="customerPhone" fieldLabel="Номер телефона" defaultValue={true} fieldValue={saleData?.customer?.customerPhone} />
                <TextInput fieldType="text" fieldName="customerName" fieldLabel="Имя и фамилия" defaultValue={true} fieldValue={saleData.customer.customerName} />
                <TextInput fieldType="text" fieldName="customerEmail" fieldLabel="E-mail" defaultValue={true} fieldValue={saleData.customer.customerEmail} />
                <TextInput fieldType="text" fieldName="customerInstagram" fieldLabel="Instagram" defaultValue={true} fieldValue={saleData.customer.customerInstagram} />

                <InPageSectionTitle>Билеты</InPageSectionTitle>
                <table className="noHover">
                    <thead>
                    <tr>
                        <th># билета</th>
                        <th>Класс</th>
                    </tr>
                    </thead>
                    <tbody>
                    {saleData.tickets.map((item, index) => (
                        <tr key={index}>
                            <td>{item.ticketNum}</td>
                            <td>{getTktClass(item.ticketClassID)}</td>
                            <td><button className="cancel-btn" onClick={() => handleCancelTicket(item.ticketID)}>Отменить</button></td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="3">
                            {(saleData.saleStatus === 'deposit' || saleData.saleStatus === 'paid') &&
                                <div className="rightAlign">
                                    <span className="inPageLink" onClick={() => window.open(`https://api.kislov.wine/tkts/d/${saleData.secretLink}`, "_blank")}>Скачать билеты</span> | <span onClick={() => copyLink(`https://api.kislov.wine/tkts/d/${saleData.secretLink}`)} className="inPageLink">Линк на билеты</span>
                                    {showMsg ? <GeneralMsg>Линк скопирован.</GeneralMsg> : ''}
                                </div>
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>

                <InPageSectionTitle>Комментарии к заказу</InPageSectionTitle>
                <textarea name="saleComments" defaultValue={saleData.saleComments} onChange={(e) => changeComments(e.target.value) } />

                <div className="addNew rightAlign">
                    <Button btnOnClick={saveComments}>Сохранить</Button>
                </div>
            </div>
        </div>
    );
}

export default EditOrder;