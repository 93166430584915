import React from 'react';
import "./../../assets/styles/ui.scss";

function EventTitle(props) {
    return (
        <div className="eventTitle">
            <span className="eventType">{props.type}:&nbsp;</span> {props.children}
        </div>
    );
}

export default EventTitle;