import React, {useState} from 'react';
import Header from "./components/Header";
import MainBar from "./components/MainBar";
import AllEvents from "./pages/AllEvents";
import Footer from "./components/Footer";
import {
    BrowserRouter,
    Routes,
    Route, Navigate,
} from "react-router-dom";
import EventTypes from "./pages/EventTypes";
import EventNew from "./pages/EventNew";
import Event from "./pages/Event";
import NewOrder from "./pages/NewOrder";
import EditOrder from "./pages/EditOrder";
import Scanner from "./pages/Scanner";
import ScannerEvent from "./pages/ScannerEvent";
import NotFound from "./../public/NotFound";
import MenuToggleContext from "./utils/MenuToggle";
import Menu from "./components/Menu";
import AppWideDataContext from "./utils/AppWideData";

function ManageApp(props) {

    return (
        <AppWideDataContext>
            <div>
                <MenuToggleContext>
                    <div className="fixedHeader">
                        <Header />
                        <Menu />
                        <MainBar />
                    </div>
                    <Routes>
                        <Route path="/" exact element={<AllEvents />} />
                        <Route path="/eventTypes" element={<EventTypes />} />
                        <Route path="/eventNew" element={<EventNew />} />
                        <Route path="/event/:id" element={<Event />} />
                        <Route path="/event/:id/:saleID" element={<EditOrder />} />
                        <Route path="/event/:id/new" element={<NewOrder />} />
                        <Route path="/welcome" element={<Scanner />} />
                        <Route path="/welcome/:id" element={<ScannerEvent />} />
                        <Route path="*" element={<NotFound/>} />
                    </Routes>
                    <Footer />
                </MenuToggleContext>
            </div>
        </AppWideDataContext>
    );
}

export default ManageApp;