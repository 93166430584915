import React, {useContext} from 'react';
import "./../assets/styles/mainbar.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faQrcode, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import {MenuToggle} from "./../utils/MenuToggle";

function MainBar(props) {
    const {menu, setMenu} = useContext(MenuToggle);

    return (
        <div className="mainbar">
            <div className="box">
                <div className={"menuBtn" + (menu ? " opened" : "")} onClick={() => setMenu(!menu)}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
            </div>
            <div className="box">
                <Link to="/welcome">
                    <div className="scanTickets">
                        <div className="icon">
                            <FontAwesomeIcon icon={faQrcode} />
                        </div>
                        <div className="text">
                            Прием гостей
                        </div>
                    </div>
                </Link>
            </div>
            <div className="box search">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
            <div className="box user">
                USER
            </div>
        </div>
    );
}

export default MainBar;