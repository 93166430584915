import React, {useEffect, useState} from 'react';
import SectionTitle from "./ui/SectionTitle";
import SingleEventSummary from "./SingleEventSummary";
import {getTodayDate} from "./../../utils/TranslateDate";
import {getUpcomingEvents} from "manage/utils/Services/Events";

function EventsToday(props) {
    const [allEvents, setAllEvents] = useState([]);

    const getEventsList = async () => {
        await getUpcomingEvents().then(response => {
            setAllEvents(response.filter((item) => item.eventDate.includes(getTodayDate())));
        });
    }

    useEffect(() => {
        getEventsList();
    }, []);
    return (
        <div className="eventsToday">
            <SectionTitle>Сегодня на винодельне</SectionTitle>
            { allEvents.length === 0 || allEvents === undefined ?
                <div className="general-alert">Сегодня мероприятий нет.</div>
                :
                allEvents.map((item, index) => (
                    <SingleEventSummary eventData={item} key={index} />
                ))
            }
        </div>
    );
}

export default EventsToday;