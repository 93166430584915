import React, {createContext, useState} from 'react'

export const MenuToggle = createContext({});

const MenuToggleContext = (props) => {
    const [menu, setMenu] = useState(false);
    return (
        <MenuToggle.Provider value={{menu, setMenu}}>
            {props.children}
        </MenuToggle.Provider>
    )
}

export default MenuToggleContext;