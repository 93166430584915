import React, {useState, useContext, useEffect} from 'react';
import SectionTitle from "./ui/SectionTitle";
import SingleEventSummary from "./SingleEventSummary";
import {getAllEvents} from "manage/utils/Services/Events";
import LoaderComponent from "manage/components/ui/Loader";

function EventsFilter(props) {
    const [allEvents, setAllEvents] = useState([]);

    const getEventsList = async () => {
        await getAllEvents().then(response => setAllEvents(response));
    }

    useEffect(() => {
        getEventsList();
    }, []);

    const filterEvents = (filterBy) => {
        const today = new Date();

        const currentMonth = today.getMonth()+1;
        const currentWeekDay = today.getDay();
        const currentDate = today.getDate();
        const currentYear = today.getFullYear();
        const lastDayOfMonth = new Date(currentYear, currentMonth, 0, 23,59,59);

        const now = Date.parse(currentYear+"-"+currentMonth+"-"+currentDate);
        let startDate = now;
        let endDate = now;

        let events = [];

        switch(filterBy) {
            case "passed":
                events = allEvents.filter(item => Date.parse(item.eventDate) < now);
                break;
            case "planned":
                events = allEvents.filter(item => Date.parse(item.eventDate) > now);
                break;
            case "week":
                startDate = new Date(today.setDate((today.getDate() - currentWeekDay) + 1));
                endDate = new Date(today.setDate((today.getDate() + 6)));
                endDate = new Date(endDate.setHours(23,59,59));
                events = allEvents.filter(item => Date.parse(startDate.toString()) < Date.parse(item.eventDate) && Date.parse(item.eventDate) < Date.parse(endDate.toString()));
                break;
            case "month":
                startDate = new Date(currentYear, currentMonth-1, 1);
                endDate = lastDayOfMonth;
                events = allEvents.filter(item => Date.parse(startDate.toString()) < Date.parse(item.eventDate) && Date.parse(item.eventDate) < Date.parse(endDate.toString()));
                break;
            default:
                events = allEvents;
                break;
        }

        return events;
    }

    const [filteredEvents, setFilteredEvents] = useState(filterEvents("all"));

    useEffect(() => {
        setFilteredEvents(filterEvents("all"));
    }, [allEvents]);

    return (
        <div className="eventsFilter">
            <SectionTitle>Фильтрация</SectionTitle>
            <div className="filterMenu">
                <ul>
                    <li onClick={() => setFilteredEvents(filterEvents("month"))}>В этом месяце</li>
                    <li onClick={() => setFilteredEvents(filterEvents("week"))}>На этой неделе</li>
                    <li onClick={() => setFilteredEvents(filterEvents("all"))}>Все</li>
                    <li onClick={() => setFilteredEvents(filterEvents("passed"))}>Прошедшие</li>
                    <li onClick={() => setFilteredEvents(filterEvents("planned"))}>Запланированные</li>
                </ul>
            </div>
            {allEvents.length === 0 && <LoaderComponent />}
            {filteredEvents.map((item, i) => (
                <SingleEventSummary eventData={item} key={i} />
            ))}
        </div>
    );
}

export default EventsFilter;