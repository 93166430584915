import useApi from "utils/api";

export async function getTicketClassesByEvent(eventID) {

    try{
        const response = await useApi.get(`/tickets/classes/${eventID}`);
        return response.data;
    }catch(error) {
        return [];
    }

}

export async function getSaleInfo(saleID) {

    try{
        const response = await useApi.get(`/sales/sale/${saleID}`);
        return response.data;
    }catch(error) {
        return [];
    }

}

export async function createNewSale(data) {
    const response = await useApi.post(`/sales/create`, data);
    return response;
}

export async function updateSale(saleID, data) {
    data = {
        "saleStatus": data.saleStatus,
        "saleComments": data.saleComments
    }
    const response = await useApi.post(`/sales/update/${saleID}`, data);
    return response.data;
}

export async function cancelSale(saleID) {
    const data = {
        "saleStatus": "cancelled"
    }
    const response = await useApi.post(`/sales/update/${saleID}`, data);
    return response;
}

export async function uploadPayConfirm(img,event,sale) {
    let data = new FormData();
    data.append("images", img);
    data.append("eventID", event);
    data.append("saleID", sale);
    const response = await useApi.post(`/upload/upPayment`, data);
    return response.data;
}
