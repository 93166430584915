import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

function ScannerHeader(props) {
    return (
        <div className="scannerHeader">
            <div className="icon"><FontAwesomeIcon icon={faQrcode} /></div>
            <div className="title">Прием гостей</div>
        </div>
    );
}

export default ScannerHeader;